@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
}

body, html {
    min-height: 100vh;
    width: 100%;
    height: 100%;
    font-family: "Arial", sans-serif;
    margin: 0;
    background-repeat: no-repeat;
    background-size: 100%;
}

code {
    @apply bg-gray-400/80 rounded-md px-1 py-0.5
}

p {
    @apply break-all
}

h1 {
    @apply text-3xl font-bold
}

h2 {
    @apply text-2xl font-bold
}

h3 {
    @apply text-xl font-bold
}

h4 {
    @apply text-lg font-bold
}

p a {
    @apply text-blue-500 underline
}

#root {
    @apply h-full
}

.basic-border {
    @apply border border-black
}

.button {
    @apply px-2 py-1 cursor-pointer rounded shadow-lg
}

.button-gray {
    @apply button bg-gray-300 hover:bg-gray-200
}

.button-green {
    @apply button bg-green-500 hover:bg-green-400
}

.button-red {
    @apply button bg-red-500 hover:bg-red-400
}

.custom-container {
    @apply bg-white/80 basic-border p-2 rounded-md
}

.f-col {
    @apply flex flex-col
}

.f-row {
    @apply flex flex-row
}

.modal-content {
    @apply flex flex-col px-6 py-3 bg-white rounded-xl
}

.modal-wrap {
    @apply fixed w-screen h-screen top-0 left-0 bg-gray-600/50 z-10 flex items-center justify-center
}